import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      // Chamar a API para autenticar o usuário
      const response = await fetch('URL_DA_SUA_API/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { token, expiration } = data;

        // Armazenar token e tempo de expiração no localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expiration);

        // Redirecionar para a página de perfil
        history.push('/profile');
      } else {
        console.error('Erro ao fazer login:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <>
    {/* <section class="vh-100">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <div class="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                    <div class="card-body p-5 text-center">
                        <h3 class="mb-5">Sign in</h3>
                        <div class="form-outline mb-4">
                            <input type="email" id="typeEmailX-2" class="form-control form-control-lg" />
                            <label class="form-label" for="typeEmailX-2">Email</label>
                        </div>

                        <div class="form-outline mb-4">
                            <input type="password" id="typePasswordX-2" class="form-control form-control-lg" />
                            <label class="form-label" for="typePasswordX-2">Password</label>
                        </div>

                        <div class="form-check d-flex justify-content-start mb-4">
                            <input class="form-check-input" type="checkbox" value="" id="form1Example3" />
                            <label class="form-check-label" for="form1Example3"> Remember password </label>
                        </div>

                        <button class="btn btn-primary btn-lg btn-block" type="submit">Autenticar</button>

                        <hr class="my-4" />

                        <button class="btn btn-lg btn-block btn-primary" style={{backgroundColor: "#dd4b39"}} type="submit"><i class="fab fa-google me-2"></i> Sign in with google</button>
                        <button class="btn btn-lg btn-block btn-primary mb-2" style={{backgroundColor: "#3b5998"}} type="submit"><i class="fab fa-facebook-f me-2"></i>Sign in with facebook</button>

                    </div>
                </div>
            </div>
            </div>
        </div>
    </section> */}
    <div className="login-dark delay">
        <form method="post" autoComplete="off">
            <h2 className="text-center"><i>Your Welcome</i></h2>
            <div className="illustration"><i className="icon ion-ios-locked-outline"></i></div>
            <div className="form-group"><input className="form-control" type="email" name="email" placeholder="Email" autoComplete='off'/></div>
            <div className="form-group"><input className="form-control" type="password" name="password" placeholder="Password" autoComplete='off'/></div>
            <div className="form-group"><button className="btn btn-primary btn-block" type="submit">Autenticar</button></div><a href="#" className="forgot">Recuperar acesso?</a></form>
    </div>
    </>
  );
}

export default LoginPage;
