import React from 'react';

const Footer = () => {
  return (
    <>
        <div id="footer" className='delay-5'>
            <p className='border-top border-3 border-warning text-center pt-4 text-white'>Todos os direitos reservados | &copy; 2024</p>
            <p className='text-center text-white fst-italic mb-0'>"Os Alumínios"</p>
            <div className='text-right w-100 pb-2'>
              <span><small>v.1.3</small></span>
            </div>
        </div>
    </>
  );
}

export default Footer;
