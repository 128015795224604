import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { faUnity } from '@fortawesome/free-brands-svg-icons';

const NavFooterTab = () => {
    return (
        <Navbar expand="lg" bg="dark" variant="dark" className="fixed-bottom">
            <Container>
                <Nav className="col-4 text-center pr-0">
                    <Nav.Link as={NavLink} to="/contact"><FontAwesomeIcon size="2x" icon={faEnvelope} /></Nav.Link>
                </Nav>
                <Nav className="col-4 text-center pr-0">
                    <Nav.Link as={NavLink} to="/"><FontAwesomeIcon size="2x" icon={faUnity} /></Nav.Link>
                </Nav>
                <Nav className="col-4 pr-0 text-center">
                    <Nav.Link as={NavLink} to="/login"><FontAwesomeIcon size="2x" icon={faUser} /></Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default NavFooterTab;


        {/* <nav className="mobile-bottom-nav navbar-dark bg-dark">
            <div className="mobile-bottom-nav__item mobile-bottom-nav__item--active col-4  text-center">
                <div className="mobile-bottom-nav__item-content pt-2">
                    <Nav.Link as={NavLink} to="/contact"><FontAwesomeIcon size="2x" icon={faEnvelope} /></Nav.Link>
                </div>		
            </div>
            <div className="mobile-bottom-nav__item col-4 text-center">		
                <div className="mobile-bottom-nav__item-content pt-2">
                    <Nav.Link as={NavLink} to="/"><FontAwesomeIcon size="2x" icon={faUnity} /></Nav.Link>
                </div>
            </div>
            <div className="mobile-bottom-nav__item col-4 text-center">
                <div className="mobile-bottom-nav__item-content text-center pt-2">
                    <Nav.Link as={NavLink} to="/login"><FontAwesomeIcon size="2x" icon={faUser} /></Nav.Link>
                </div>		
            </div>
        </nav> */}