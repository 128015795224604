import React from 'react';

const AboutPage = () => {
  return (
    <>
    <div className='container delay-5'>
      <h1 className='text-center mt-5 text-uppercase font-weight-bold'>Sobre nós</h1>
      <div className="row" style={{padding: 40}}>
        <div className="col-sm pb-5">
          <img src="images/nuno_simoes.jpg" alt="Nuno Simões" className="img-fluid rounded-image" />
          <h3 className='text-center font-italic font-weight-light text-lowercase'>El professor</h3>
        </div>
        <div className="col-sm pb-5">
          <img src="images/luis_ferreira.jpg" alt="Luís Ferreira" className="img-fluid rounded-image" />
          <h3 className='text-center font-italic font-weight-light text-lowercase'>Berlim</h3>
        </div>
        <div className="col-sm pb-5">
          <img src="images/kael_sa.jpg" alt="Kael de Sá" className="img-fluid rounded-image" />
          <h3 className='text-center font-italic font-weight-light text-lowercase'>Denver</h3>
        </div>
        <div className="col-sm pb-5">
          <img src="images/paulo_henriques.jpg" alt="Paulo Henriques" className="img-fluid rounded-image" />
          <h3 className='text-center font-italic font-weight-light text-lowercase'>Helsinki</h3>
        </div>
        <div className="col-sm">
          <img src="images/ricardo_novo.jpg" alt="Ricardo Novo" className="img-fluid rounded-image" />
          <h3 className='text-center font-italic font-weight-light text-lowercase'>Rio</h3>
        </div>
      </div>
    </div>
  </>
  );
}

export default AboutPage;
