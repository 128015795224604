import React, { useState } from 'react';

const SplashScreen = ({ onFinishLoading }) => {
  const [loading, setLoading] = useState(!localStorage.getItem('splashScreenFinished'));

  const handleContinue = () => {
    setLoading(false);
    onFinishLoading();
  };

  return (
    <div className='delay-5'>
      {loading ? (
        <>
        <div id="logo" className="logoContainer"></div>
        <div style={{marginTop: "0px", textAlign: "center"}}>
            <button className='btoGO' onClick={handleContinue}>GO</button>
        </div>
        </>
      ) : (
        // Renderizar null se o loading for false
        null
      )}
    </div>
  );
}

export default SplashScreen;