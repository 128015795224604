import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NavigationMenu = () => {
    const [expanded, setExpanded] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        // Verificar se há um token de acesso armazenado localmente
        const token = localStorage.getItem('token');
        setAuthenticated(!!token);
    }, []);

    const handleLogout = () => {
        // Limpar o token de acesso armazenado localmente
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        setAuthenticated(false);
    };

    return (
        <Navbar expanded={expanded} expand="lg" bg="dark" variant="dark" className="fixed-top">
            <Container>
                <Navbar.Brand as={NavLink} to="/"><img className='ml-4' src="images/u_trans.png" width={30} alt="UAW" />
                {/* <h1>UAW</h1> */}
                    <small className='slogan pt-2'><i>You are Welcome</i></small>
                </Navbar.Brand>
                <Navbar.Toggle className="me-3" aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-3">
                        <Nav.Link as={NavLink} to="/" onClick={() => setExpanded(false)}>UAW</Nav.Link>
                        <Nav.Link as={NavLink} to="/about" onClick={() => setExpanded(false)}>Sobre nós</Nav.Link>
                        {authenticated && <Nav.Link as={NavLink} to="/profile" onClick={() => setExpanded(false)}>Perfil</Nav.Link>}
                        {!authenticated && <Nav.Link as={NavLink} to="/login" onClick={() => setExpanded(false)}>Autenticação</Nav.Link>}
                        {authenticated && <Nav.Link onClick={handleLogout}>Logout</Nav.Link>}
                        <Nav.Link as={NavLink} to="/contact" onClick={() => setExpanded(false)}>Contato</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationMenu;
