import React from 'react';
import MyMap from './Map';

const ContactPage = () => {
  return (
    <> 
    <MyMap /> 
       
    <div className="content delay text-white delay" style={{marginTop: 5}}>
      <h2 className="fs-1 mb-5">Contatos</h2>
      <p>Entre em contato conosco através dos seguintes meios:</p>
      <ul>
        <li>Email: <a className="text-uppercase font-weight-bold text-decoration-none" href="mailto:geral@uaw.pt">geral@uaw.pt</a> (sim funciona!)</li>
        <li>Telefone: (+351) 212 946 250</li>
      </ul>
    </div>

    </>
  );
}

export default ContactPage;
