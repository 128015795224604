import React from 'react';

const ProfilePage = ({ username }) => {
  return (
    <div>
      <h1>Perfil de {username}</h1>
      <p>Aqui você pode ver e editar seu perfil.</p>
    </div>
  );
}

export default ProfilePage;
