import React from "react"
import { Map, Marker } from "pigeon-maps"

const MyMap = () => {
  return (
    <Map height={300} defaultCenter={[38.6664, -9.1769]} defaultZoom={16}>
      <Marker 
        width={50} 
        anchor={[38.6664, -9.1769]} 
        color={"#cc00ff"}>
        <img src='/images/gps_piaget.png' width={70} height={70} alt='' />
        </Marker>
    </Map>
    )
}

export default MyMap;