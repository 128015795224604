import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import ContactPage from './components/ContactPage';
import NavigationMenu from './components/NavigationMenu';
import SplashScreen from './components/SplashScreen';
import Footer from './components/Footer';
import NavFooterTab from './components/NavFooterTab';

const App = () => {

  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const splashScreenFinished = localStorage.getItem('splashScreenFinished');
    if (splashScreenFinished) {
      setShowSplash(false);
    }
  }, []);

  const handleFinishLoading = () => {
    localStorage.setItem('splashScreenFinished', 'true');
    setShowSplash(false);
  };

  return ( 
    <Router>
      <div>   
        {showSplash && (
          <SplashScreen onFinishLoading={handleFinishLoading} />
        )}    
        {!showSplash && ( 
          <>
            <NavigationMenu />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
            <Footer />
            <NavFooterTab />
          </>
          )
        }
      </div>
    </Router>    
  );
}

export default App;
