import React from 'react';

const HomePage = () => {
  return (
    <>
      {/* <div className="background" style={{height: "100vh", marginTop: "0"}}></div> */}
      <div className="content delay">
        <img className="img-fluid pb-3" src="/images/bk_trans.png" alt="" width={"100%"} />
        <h2 className='text-center fs-1 fw-bolder'>Interligamos universitários</h2>
        <br />
        <img className="img-fluid mx-auto d-block pb-5" alt="" src="/images/home.jpg" />
        <h2 className="text-white">Em Portugal</h2>
        <h2 className="fs-1 fw-bolder">446.000 alunos</h2>
        <h2 className="text-white">no ensino superior.</h2>
        <img className="img-fluid mx-auto d-block pt-5 pb-5" alt="" src="/images/home_2.jpg" />
        <p className="text-white fs-1 text-center"><span className="fs-1 fw-bolder" style={{color: "#ffcc00"}}>Faltava</span> uma plataforma de rede social exclusiva para estudantes universitários.</p>
    </div>
    </>
  );
}

export default HomePage;
